@import '../../shared/styles.module.scss';

.Home {
    background-image: url('../../pexels-photo-1280162.webp');
    background-size: cover;
    height: calc(100vh - 136px);

    .Text{
        padding: 50px;
        margin-left: 5%;
        margin-top: 15%;
        align-self: center;
        width: fit-content;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}
