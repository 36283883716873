.ContactUs {
    height: calc(100vh - 136px);

    img {
        max-height: 300px;
        max-width: 300px;
    }

    .Info {
        margin: auto;
        margin-top: 15px;
        max-width: 300px;
        text-align: left !important;
    }

    .Form {
        max-width: 400px;
        min-width: 300px;
        margin: auto;
    }
}
