@import '../../shared/styles.module.scss';

.Footer{
    width: 100%;
    padding: 1rem 0;
    background-color: $body-bg-dark;
    color: $body-bg;

    p{
        margin: 0;
    }
}