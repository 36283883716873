.Layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .Content {
        flex: 1 0 auto;
        width: 100%;

        &:after {
            content: '\00a0';
            display: block;
            height: 0;
            visibility: hidden;
        }
    }
}
